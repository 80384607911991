<template>
    <div>
        <el-dialog :visible.sync="sta.show">
            <span slot="title">选择用户</span>
            <div class="form-group">
                <div v-for="(item,index) in list" :key="'bm_'+index" class="abm">
                    <div class="bm">
                        <el-checkbox
                            @change="(val)=>clickAll(val,item.bm,index)">{{item.bm}}({{item.lss.length}})</el-checkbox>
                    </div>
                    <el-checkbox-group v-model="teacherIds[index]" class="ls">
                        <el-checkbox v-for="ls in item.lss" :label="ls.Id" :key="ls.Id">{{ls.Name}}</el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div slot="footer" v-loading="sta.loading">
                <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
                <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                sta: {
                    show: false,
                    loading: false
                },
                list: [],
                teacherIds: [],

            }
        },
        created() {
            this.getTeachers()
        },
        methods: {
            init() {
                this.sta = { show: true, loading: false }
            },
            getTeachers() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/DOC/PowerApi/GetTeachers",
                    // data: { isPT: true },
                    finally() { self.sta.loading = false; },
                    completed: function (res) {
                        self.sta.show = false;
                        self.list = self.groupBy(res.DATA);
                    }
                })
            },
            submit() {
                let userIds = []
                this.teacherIds.forEach(x => {
                    x.forEach(y => {
                        userIds.push(y)
                    })
                })
                if (userIds.length > 0) {
                    this.$emit("on-saved", userIds);
                    this.sta = { show: false }
                }
            },
            clickAll(val, bm, index) {
                if (val) {
                    this.teacherIds[index] = this.list[index].lss.map(x => x.Id);
                }
                else {
                    this.teacherIds[index] = [];
                }
                this.$forceUpdate()
            },

            groupBy(array) {
                var result = [];
                this.teacherIds = [];
                var departs = [...new Set(array.map(x => x.Depart))];
                departs.forEach(depart => {
                    this.teacherIds.push([]);
                    var _departs = array.filter(x => x.Depart == depart);
                    result.push({
                        bm: depart == null ? '无部门' : depart,
                        lss: _departs
                    })
                });
                return result;
            },
        }
    }
</script>

<style lang="less" scoped>
    .abm {
        border: 1px solid #e7e7ec;
        margin-bottom: 10px;

        .bm {
            background: #efeff3;
            padding-left: 5px;
        }

        .ls {
            padding-left: 5px;
        }
    }
</style>