<template>
    <div>
        <el-dialog :visible.sync="sta.show">
            <span slot="title">选择用户({{list.length}})</span>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <el-radio-group v-model="teacherId">
                            <el-radio :label="item.Id" :key="item.Id" v-for="item in list">{{item.Name}}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div slot="footer" v-loading="sta.loading">
                <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
                <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                sta: {
                    show: false,
                    loading: false
                },
                list: [],
                teacherId: 0
            }
        },
        created() {
            this.getTeachers()
        },
        methods: {
            init() {
                this.sta = { show: true, loading: false }
            },
            getTeachers() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/DOC/PowerApi/GetTeachers",
                    //  data: { isPT: true },
                    finally() { self.sta.loading = false; },
                    completed: function (res) {
                        self.sta.show = false;
                        self.list = res.DATA

                    }
                })
            },
            submit() {
                if (this.teacherId > 0) {
                    let item = this.list.find(x => x.Id == this.teacherId)
                    this.$emit("on-saved", item);
                    this.sta = { show: false }
                }
            }
        }
    }
</script>